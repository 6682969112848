export const orderTableColumns = [
  {
    title: '订单名称',
    dataIndex: 'buyer_address',
    scopedSlots: { customRender: 'buyer_address' },
  },
  {
    title: '下单联系人',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '订单状态',
    dataIndex: 'dispatch_status',
    scopedSlots: { customRender: 'dispatch_status' },
  },
  {
    title: '订单来源',
    dataIndex: 'order_from',
    scopedSlots: { customRender: 'order_from' },
  },
]
// 字段有变动
export const orderStatusColumns = [
  {
    title: '订单名称',
    dataIndex: 'buyer_address',
    scopedSlots: { customRender: 'buyer_address' },
  },
  {
    title: '下单客户',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '客户账号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '截止时间',
    dataIndex: 'expire_time',
    scopedSlots: { customRender: 'expire_time' },
  },
  {
    title: '订单状态',
    dataIndex: 'dispatch_status',
    scopedSlots: { customRender: 'dispatch_status' },
  },
  // {
  //   title: '订单来源',
  //   dataIndex: 'order_from',
  //   scopedSlots: { customRender: 'order_from' },
  // },
  // 服务站暂时注释
  {
    title: '服务站',
    dataIndex: 'station_id',
    scopedSlots: { customRender: 'station_id' },
  },
  {
    title: '是否延期',
    dataIndex: '',
    scopedSlots: { customRender: 'is_expire' },
  },
  {
    title: '是否结算',
    dataIndex: 'financial_url',
    scopedSlots: { customRender: 'financial_url' },
  },
]

export const cloudOrderTableColumns = [
  // {
  //   title: '订单号',
  //   dataIndex: 'number',
  //   scopedSlots: { customRender: 'number' },
  // },
  {
    title: '订单名称',
    dataIndex: 'order_name',
    scopedSlots: { customRender: 'order_name' },
  },
  {
    title: '下单客户',
    dataIndex: 'user_name',
    scopedSlots: { customRender: 'user_name' },
  },
  // {
  //   title: '客户账号',
  //   dataIndex: 'phone',
  //   scopedSlots: { customRender: 'phone' },
  // },
  {
    title: '销售',
    dataIndex: 'salesman_id',
    scopedSlots: { customRender: 'salesman_id' },
  },
  {
    title: '服务站',
    dataIndex: 'station_id',
    scopedSlots: { customRender: 'station_id' },
  },
  // {
  //   title: '订单需求',
  //   dataIndex: 'note',
  //   scopedSlots: { customRender: 'note' },
  // },
  // {
  //   title: '订单图片',
  //   dataIndex: 'pictures',
  //   scopedSlots: { customRender: 'pictures' },
  // },
  {
    title: '订单报价',
    dataIndex: 'quote_price',
    scopedSlots: { customRender: 'quote_price' },
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
    scopedSlots: { customRender: 'order_status' },
  },
  {
    title: '下单时间',
    dataIndex: 'create_time',
  },
  // {
  //   title: '是否发起过售后',
  //   dataIndex: 'aftersales_type',
  //   scopedSlots: { customRender: 'aftersales_type' },
  // },
  // {
  //   title: '赔付金额',
  //   dataIndex: 'compensation_price',
  //   scopedSlots: { customRender: 'compensation_price' },
  // },
  {
    title: '付款时间',
    dataIndex: 'pay_time',
    scopedSlots: { customRender: 'pay_time' },
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
]
